import { Rules, ColumnsFile } from "../validations/Rules";
import ResultTest from "../validations/ResultTest";
import { isSameDay, parse } from "date-fns";
import isEmpty from "../../../../utils/isEmpty";

/**
 * agrega los errores de back a la lista ya existente de errores de front
 * @param {*} matchListBd
 * @param {*} excelColumList
 * @param {*} infoColumn
 * @param {*} results
 */
const addBackValidations = (matchListBd, excelColumList, infoColumn, results, list, name) => {
  const BreakException = {};
  var resultError = [];
  resultError = results;
  if (matchListBd !== undefined) {
    excelColumList.forEach((cell, index) => {
      if (!isEmpty(cell)) {
        try {
          var valor = cell;
          var listValidate = list[index][infoColumn.name];
          list.forEach((element, i) => {
            if (i === index) {
              element.line = index + 1;
            }
          });

          if (name === "fecha") {
            var resultValidateExpire = validatemonthday(listValidate, index, ColumnsFile.vencimientoColumn.name);

            if (!!resultValidateExpire) {
              resultError.push(resultValidateExpire);
            }

            var resultValidateMont;
            resultValidateMont = validatemonthday(listValidate, index, infoColumn.name);

            const resultIssueDate = validateIssueDate(listValidate, index, infoColumn.name);
            if (!!resultIssueDate) {
              resultError.push(resultIssueDate);
            }

            if (!!resultValidateMont) {
              resultError.push(resultValidateMont);
            }

            var resultDiscount = validateValueDiscountNumeric(list, index);

            if (!!resultDiscount) {
              resultError.push(resultDiscount);
            }
          }

          if (matchListBd !== null) {
            matchListBd.forEach((itemBd) => {
              let strValdiation = "";
              if (name === "retefuente") {
                let value = parseFloat(cell);
                valor = value.toFixed(2).toString();
              }
              strValdiation = getStringToValidate(infoColumn, itemBd);

              let value = validateValue(valor, name);
              let valueValidation = validateValue(strValdiation, name);
              if (value === valueValidation) {
                list[index][infoColumn.name] = itemBd;

                if (itemBd.id === 0 && name !== "articulo") {
                  const validationBackError = new ResultTest(
                    infoColumn.msgBackValidations + " [" + cell.replace("null", " ") + "]",
                    index + 1,
                    infoColumn.name
                  );
                  resultError.push(validationBackError);
                  throw BreakException;
                }

                if (name === "prefijo") {
                  var validateDateExpiratio = validateDateExpiration(list, index, resultError);

                  if (!!validateDateExpiratio) {
                    resultError.push(validateDateExpiratio);
                  }

                  let dateNow = new Date(new Date().toDateString());
                  let dateInit = new Date(itemBd.fechaInicio);
                  let dateEnd = new Date(itemBd.fechaFinal);

                  if (itemBd.id === 0 && name !== "articulo") {
                    const validationBackError = new ResultTest(infoColumn.msgBackValidations + " [" + cell + "]", index + 1, infoColumn.name);
                    resultError.push(validationBackError);
                    //Se agrega esto para hacer un break y no seguir validando campos
                    throw BreakException;
                  }

                  if (itemBd.id > 0 && itemBd.idstate !== 0) {
                    const validationBackError = new ResultTest(
                      Rules.prefijoNoDisponible.pattern.message + " [" + cell + "]",
                      index + 1,
                      infoColumn.name
                    );
                    resultError.push(validationBackError);
                    //Se agrega esto para hacer un break y no seguir validando campos
                    throw BreakException;
                  }

                  if (dateNow < dateInit || dateEnd < dateInit) {
                    const validationBackError = new ResultTest(
                      Rules.prefijoResolucion.pattern.message + " [" + cell + "]",
                      index + 1,
                      infoColumn.name
                    );
                    resultError.push(validationBackError);
                    throw BreakException;
                  }

                  if (parseInt(itemBd.consecutivoActual) > parseInt(itemBd.consecutivoFinal)) {
                    const validationBackError = new ResultTest(
                      Rules.resolucionColumn.pattern.message + " [" + cell + "]",
                      index + 1,
                      infoColumn.name
                    );
                    resultError.push(validationBackError);
                    throw BreakException;
                  }
                }

                if (name === "articulo") {
                  if (itemBd.id !== 0) {
                    var indexArticle = resultError.findIndex((o) => {
                      return o.line === index + 1 && o.key === ColumnsFile.nombreArticuloColumn.name;
                    });

                    if (indexArticle !== -1) resultError.splice(indexArticle, 1);

                    var indexUnit = resultError.findIndex((o) => {
                      return o.line === index + 1 && o.key === ColumnsFile.unidadMedidaColumn.name;
                    });

                    if (indexUnit !== -1) resultError.splice(indexUnit, 1);

                    return resultError;
                  }

                  return resultError;
                }
              }
            });
          }
        } catch (ex) {
          console.log("Exeption Validation --->", ex);
          if (ex !== BreakException) throw ex;
          return resultError;
        }
      }
    });
  }
};

/**
 * Validacion de Campos reteiva, reteica o retefuente del cargue masivo de facturas
 * @param {*} value
 * @param {*} name
 * @returns
 */
const validateValue = (value, name) => {
  if (name === "reteiva" || name === "reteica" || name === "retefuente") {
    var result = parseFloat(value)
      .toFixed(2)
      .toString();
    return result.toString();
  }

  return value;
};

export const addBackValidationsGeneral = (itemDistinctList, itemAllList, results, list) => {
  var resultsValidation = results;
  var listValidation = list;

  addBackValidations(itemDistinctList.cliente, itemAllList.clienteList, ColumnsFile.clienteColumn, resultsValidation, listValidation, "cliente");

  addBackValidations(
    itemDistinctList.medioPago,
    itemAllList.medioPagoList,
    ColumnsFile.medioPagoColumn,
    resultsValidation,
    listValidation,
    "medioPago"
  );
  addBackValidations(
    itemDistinctList.articulo,
    itemAllList.articuloList,
    ColumnsFile.refArticuloColumn,
    resultsValidation,
    listValidation,
    "articulo"
  );

  addBackValidations(null, itemAllList.fechaList, ColumnsFile.fechaColumn, resultsValidation, listValidation, "fecha");
  addBackValidations(
    itemDistinctList.reteFuente,
    itemAllList.reteFuenteList,
    ColumnsFile.reteFuenteColumn,
    resultsValidation,
    listValidation,
    "retefuente"
  );

  addBackValidations(itemDistinctList.prefijo, itemAllList.prefijoList, ColumnsFile.resolucionColumn, resultsValidation, listValidation, "prefijo");

  addBackValidations(
    itemDistinctList.formaPago,
    itemAllList.formaPagoList,
    ColumnsFile.formaPagoColumn,
    resultsValidation,
    listValidation,
    "formaPago"
  );

  addBackValidations(itemDistinctList.vendedor, itemAllList.vendedorList, ColumnsFile.vendedorColumn, resultsValidation, listValidation, "vendedor");

  addBackValidations(
    itemDistinctList.unidadMedida,
    itemAllList.unidadMedidaList,
    ColumnsFile.unidadMedidaColumn,
    resultsValidation,
    listValidation,
    "unidadMedida"
  );

  addBackValidations(itemDistinctList.reteIca, itemAllList.reteIcaList, ColumnsFile.reteicaColumn, resultsValidation, listValidation, "reteica");

  addBackValidations(itemDistinctList.reteIva, itemAllList.reteIvaList, ColumnsFile.reteivaColumn, resultsValidation, listValidation, "reteiva");
};

/**
 * Metodos internos
 */

const getStringToValidate = (infoColumn, item) => {
  if (infoColumn.name === ColumnsFile.clienteColumn.name) {
    return item.tipo + "-" + item.numero;
  }
  if (infoColumn.name === ColumnsFile.medioPagoColumn.name) {
    return item.valor;
  }
  if (
    infoColumn.name === ColumnsFile.reteivaColumn.name ||
    infoColumn.name === ColumnsFile.reteFuenteColumn.name ||
    infoColumn.name === ColumnsFile.reteicaColumn.name
  ) {
    if (item.valor !== "NaN") {
      return item.valor.toFixed(2).toString();
    }
  }
  return item.valor;
};

/**
 * Función para validar mes o dia de una fecha ingresada.
 */
const validatemonthday = (date, index, column) => {
  var result;
  var arrayDate = date.split(/\/|-/);

  var fileDay = arrayDate[0];
  var fileMonth = arrayDate[1];

  if (fileDay > 31) {
    return new ResultTest(Rules.diaMayor.pattern.message + " [" + fileDay + "]", index + 1, column);
  }

  if (fileDay < 1) {
    result = new ResultTest(Rules.diaMenor.pattern.message + " [" + fileDay + "]", index + 1, column);
    return result;
  }

  if (fileMonth > 12) {
    result = new ResultTest(Rules.mesMayor.pattern.message + " [" + fileMonth + "]", index + 1, column);
    return result;
  }

  if (fileMonth < 1) {
    result = new ResultTest(Rules.mesMenor.pattern.message + " [" + fileMonth + "]", index + 1, column);
    return result;
  }
};

/**
 * Función para validar fecha de emision, esta fecha debe ser igual a la fecha actual para el registro de facturas, según anexo 1.9.
 * @param {*} date, fecha ingresada en la plantilla
 * @param {*} index
 * @param {*} column
 * @returns
 */
const validateIssueDate = (date, index, column) => {
  const currentDate = new Date();
  const issueDate = parse(date, "dd/MM/yyyy", new Date());

  if (!isSameDay(issueDate, currentDate)) {
    return new ResultTest(Rules.fechaEmision.pattern.message + " [" + date + "]", index + 1, column);
  }
};

/* 
/**
 * Función paa validar fecha de emision , esta fecha no puede ser menor a mas de 10 días desde la fecha de registro de facturas.
 * @param {*} date
 * @param {*} index
 * @param {*} column
 * @returns
 */

// const validatedate = (date, index, column) => {
//   var currentDate = new Date();
//   currentDate.setDate(currentDate.getDate() - 10);

//   var arrayDate = date.split(/\/|-/);

//   var day = arrayDate[0];
//   var month = arrayDate[1];
//   var year = arrayDate[2];

//   if (!!year && year.length === 2) {
//     year = `20${year}`;
//   }

//   var date1 = new Date(
//     currentDate.getFullYear(),
//     currentDate.getMonth() + 1,
//     currentDate.getDate()
//   );
//   var date2 = new Date(year, month, day);

//   if (date2 < date1) {
//     return new ResultTest(
//       Rules.fechaEmision.pattern.message + " [" + date + "]",
//       index + 1,
//       column
//     );
//   }
// };

/**
 * Funcion que valida que el valor del descuento numerico no sea mayor a la multiplicacion del campo cantidad por valor unitaripo del articulo
 */
const validateValueDiscountNumeric = (list, index) => {
  var typeDiscount = list[index][ColumnsFile.tipoDescuentoColumn.name];
  var valueDiscount = list[index][ColumnsFile.valorDescuentoColumn.name];

  if (typeDiscount === "$" && valueDiscount.length !== 0) {
    var valueunit = list[index][ColumnsFile.valorUnitarioColumn.name];
    var count = list[index][ColumnsFile.cantidadColumn.name];

    var result = valueunit * count;

    if (parseInt(valueDiscount) >= result) {
      return new ResultTest(
        Rules.descuentoSuperaMonto.pattern.message + " [" + valueDiscount + "]",
        index + 1,
        ColumnsFile.valorDescuentoColumn.name
      );
    }

    if (parseInt(valueDiscount) < 0) {
      return new ResultTest(Rules.descuentoNoNegativo.pattern.message + " [" + valueDiscount + "]", index + 1, ColumnsFile.valorDescuentoColumn.name);
    }
  }
  if (typeDiscount === "%") {
    if (parseFloat(valueDiscount) > 100) {
      return new ResultTest(Rules.descuentoValorMayor.pattern.message + " [" + valueDiscount + "]", index + 1, ColumnsFile.valorDescuentoColumn.name);
    }

    if (parseFloat(valueDiscount) < 0) {
      return new ResultTest(Rules.descuentoNoNegativo.pattern.message + " [" + valueDiscount + "]", index + 1, ColumnsFile.valorDescuentoColumn.name);
    }
  }
};

/**
 * Función para validar que si la forma de pago es contado ebe seleccionar un medio de pago , y si la froma de pago es credito se debe validar que la fecha de pago sea obligatoria
 */

/*const validateWayPay = (list, index) => {

    var wayPay =  list[index][ColumnsFile.formaPagoColumn.name];
    var payment =  list[index][ColumnsFile.medioPagoColumn.name];

    if((wayPay.id === 1) || ((removeAccents(wayPay).toUpperCase() !== 'CREDITO') && payment.id === 98)){

        return new ResultTest(Rules.motivoFormaPagoContado.pattern.message, index+1, ColumnsFile.medioPagoColumn.name);
    }
}*/

/**
 * Función de validación de fecha  de vencimiento debe ser mayor a la fecha que se encuentra en la columna fecha.
 * @param {*} list
 * @param {*} index
 * @returns
 */
const validateDateExpiration = (list, index) => {
  var dateExpiration = list[index][ColumnsFile.vencimientoColumn.name];
  var wayPay = list[index][ColumnsFile.formaPagoColumn.name];
  var dateCreate = list[index][ColumnsFile.fechaColumn.name];

  var arrayDateCreate = dateCreate.split(/\/|-/);
  var arrayDateExpiration = dateExpiration.split(/\/|-/);

  var dayCreate = arrayDateCreate[0];
  var monthCreate = arrayDateCreate[1];
  var yearCreate = arrayDateCreate[2];

  if (!!yearCreate && yearCreate.length === 2) {
    yearCreate = `20${yearCreate}`;
  }

  var dayExpiration = arrayDateExpiration[0];
  var monthExpiration = arrayDateExpiration[1];
  var yearExpiration = arrayDateExpiration[2];

  if (!!yearExpiration && yearExpiration.length === 2) {
    yearExpiration = `20${yearExpiration}`;
  }

  if (
    (wayPay.id === 2 && dateExpiration.length === 0) ||
    (wayPay.id === 2 && !!dateExpiration) ||
    (removeAccents(wayPay).toUpperCase() === "CREDITO" && dateExpiration.length === 0)
  ) {
    return new ResultTest(Rules.motivoFormaPagoCredito.pattern.message, index + 1, ColumnsFile.vencimientoColumn.name);
  }

  if (new Date(yearExpiration, monthExpiration - 1, dayExpiration) < new Date(yearCreate, monthCreate - 1, dayCreate)) {
    return new ResultTest(Rules.fechaVencimiento.pattern.message, index + 1, ColumnsFile.vencimientoColumn.name);
  }
};

/**
 * Funcion para remover acentos de una palabra , para este caso se debe remover el acento de la palabra Crédito
 * @param {*} str
 * @returns
 */
const removeAccents = (str) => {
  var remove = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  return remove;
};
